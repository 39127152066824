import React, { useState } from "react"
import { Card, Form, Row, Col } from "react-bootstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phoneNumber: Yup.string()
    .min(7, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  streetAddress: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  city: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  stateOrRegion: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  country: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  websiteUrl: Yup.string()
    .min(5, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  productDescription: Yup.string()
    .min(50, "Too Short!")
    .max(500, "Too Long!")
    .required("Required"),
  competitors: Yup.string()
    .min(50, "Too Short!")
    .max(500, "Too Long!")
    .required("Required"),
  revenueLastSixMonths: Yup.string()
    .min(1, "Too Short!")
    .max(150, "Too Long!")
    .required("Required"),
  existingFunding: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  reasonForApplication: Yup.string()
    .min(50, "Too Short!")
    .max(500, "Too Long!")
    .required("Required"),
  referralSource: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),

  email: Yup.string().email("Invalid email").required("Required"),
})

interface Props {
  onClose: () => any
}

const backendUrl = process.env.GATSBY_BACKEND_HOST

const FASTApplicationForm = ({ onClose }: Props) => {
  const [requestState, setRequestState] = useState({
    sending: false,
    sent: false,
    success: false,
    error: false,
  })
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      phoneNumber: "",
      streetAddress: "",
      city: "",
      stateOrRegion: "",
      country: "",
      websiteUrl: "",
      productDescription: "",
      competitors: "",
      revenueLastSixMonths: "",
      existingFunding: "",
      reasonForApplication: "",
      referralSource: "",
      miscFile: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setRequestState(prev => ({ ...prev, sending: true }))

      const formData = new FormData()

      for (const [key, value] of Object.entries(values)) {
        if (value) {
          formData.append(key, value!)
        }
      }

      axios
        .post(`${backendUrl}/fast-accelerator/apply`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setRequestState({
            sending: false,
            error: false,
            sent: true,
            success: true,
          })
        })
        .catch(err => {
          console.log(err)

          setRequestState({
            sending: false,
            success: false,
            sent: true,
            error: true,
          })
        })
    },
  })

  return (
    <Form as={Card} className="px-xl-5 py-3">
      <h3 className="mb-3 text-center">FAST Application Form</h3>
      <p className="mb-5 text-center">
        Hi there, please enter your details below to apply.
      </p>

      {/* Name Row */}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            {formik.touched.firstName && formik.errors.firstName && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.firstName}
              </span>
            )}
            <Form.Label>
              First name<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="firstName"
              id="firstName"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            {formik.touched.lastName && formik.errors.lastName && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.lastName}
              </span>
            )}
            <Form.Label>
              Last name<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="lastName"
              id="lastName"
            />
          </Form.Group>
        </Col>
      </Row>
      {/* End Name Row*/}

      {/* Email and Phone Number Row */}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            {formik.touched.email && formik.errors.email && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.email}
              </span>
            )}
            <Form.Label>
              Email address<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              id="email"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.phoneNumber}
              </span>
            )}
            <Form.Label>
              Phone number<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="tel"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phoneNumber"
              id="phoneNumber"
            />
          </Form.Group>
        </Col>
      </Row>
      {/* End Email and Phone Number Row*/}

      {/* Company Name and Website URL Row */}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            {formik.touched.companyName && formik.errors.companyName && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.companyName}
              </span>
            )}
            <Form.Label>
              Company name<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="companyName"
              id="companyName"
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group>
            {formik.touched.websiteUrl && formik.errors.websiteUrl && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.websiteUrl}
              </span>
            )}
            <Form.Label>
              Website URL<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.websiteUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="websiteUrl"
              id="websiteUrl"
            />
          </Form.Group>
        </Col>
      </Row>
      {/* End Company Name and Website URL Row */}

      {/* Street and City Row */}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            {formik.touched.streetAddress && formik.errors.streetAddress && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.streetAddress}
              </span>
            )}
            <Form.Label>
              Street address<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.streetAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="streetAddress"
              id="streetAddress"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            {formik.touched.city && formik.errors.city && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.city}
              </span>
            )}
            <Form.Label>
              City<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="city"
              id="city"
            />
          </Form.Group>
        </Col>
      </Row>
      {/* End Street and City Row */}

      {/* State/Region and Country Row */}
      <Row className="mb-3">
        <Col>
          <Form.Group>
            {formik.touched.stateOrRegion && formik.errors.stateOrRegion && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.stateOrRegion}
              </span>
            )}
            <Form.Label>
              State or Region<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.stateOrRegion}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="stateOrRegion"
              id="stateOrRegion"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            {formik.touched.country && formik.errors.country && (
              <span style={{ color: "red", display: "block" }}>
                {formik.errors.country}
              </span>
            )}
            <Form.Label>
              Country<span style={{ color: "red" }}>{" *"}</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="country"
              id="country"
            />
          </Form.Group>
        </Col>
      </Row>
      {/* End State/Region and Country Row */}

      <Form.Group className="mb-3">
        {formik.touched.productDescription &&
          formik.errors.productDescription && (
            <span style={{ color: "red", display: "block" }}>
              {formik.errors.productDescription}
            </span>
          )}
        <Form.Label>
          Please tell us about your product
          <span style={{ color: "red" }}>{" *"}</span>
        </Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          value={formik.values.productDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="productDescription"
          id="productDescription"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.competitors && formik.errors.competitors && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.competitors}
          </span>
        )}
        <Form.Label>
          Who are your competitors?<span style={{ color: "red" }}>{" *"}</span>
        </Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          value={formik.values.competitors}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="competitors"
          id="competitors"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.revenueLastSixMonths &&
          formik.errors.revenueLastSixMonths && (
            <span style={{ color: "red", display: "block" }}>
              {formik.errors.revenueLastSixMonths}
            </span>
          )}
        <Form.Label>
          Please provide your revenue of the last 6 months
          <span style={{ color: "red" }}>{" *"}</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={formik.values.revenueLastSixMonths}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="revenueLastSixMonths"
          id="revenueLastSixMonths"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.existingFunding && formik.errors.existingFunding && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.existingFunding}
          </span>
        )}
        <Form.Label>
          Do you have any funding?<span style={{ color: "red" }}>{" *"}</span>
        </Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          value={formik.values.existingFunding}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="existingFunding"
          id="existingFunding"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.reasonForApplication &&
          formik.errors.reasonForApplication && (
            <span style={{ color: "red", display: "block" }}>
              {formik.errors.reasonForApplication}
            </span>
          )}
        <Form.Label>
          What is your reason for applying to FAST?
          <span style={{ color: "red" }}>{" *"}</span>
        </Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          required
          value={formik.values.reasonForApplication}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="reasonForApplication"
          id="reasonForApplication"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {formik.touched.referralSource && formik.errors.referralSource && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.referralSource}
          </span>
        )}
        <Form.Label>
          How did you find us? (Google, LinkedIn, etc)
          <span style={{ color: "red" }}>{" *"}</span>
        </Form.Label>
        <Form.Control
          type="text"
          value={formik.values.referralSource}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="referralSource"
          id="referralSource"
        />
      </Form.Group>

      <Form.Group className="my-4">
        {formik.touched.miscFile && formik.errors.miscFile && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.miscFile}
          </span>
        )}
        <Form.Label>
          If you'd like to share any file with us (pitch deck, video, etc)
        </Form.Label>
        <Form.Control
          type="file"
          value={formik.values.miscFile}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="miscFile"
          id="miscFile"
        />
      </Form.Group>

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-light" onClick={() => onClose()}>
          Cancel
        </button>

        {requestState.error && (
          <span
            className="text-center px-2"
            style={{ fontSize: "12px", color: "red" }}
          >
            We encountered a problem. Please try again soon or contact us at
            contact@flapmax.com
          </span>
        )}
        <button
          className={`btn ${!requestState.sent && "btn-dark"} ${
            requestState.sent && requestState.success && "btn-success"
          } `}
          type="submit"
          disabled={
            (requestState.sent && requestState.success) || requestState.sending
          }
          onClick={() => formik.handleSubmit()}
        >
          {requestState.sent && requestState.success
            ? "Success"
            : requestState.error
            ? "Error"
            : "Submit"}
          {}
        </button>
      </div>
    </Form>
  )
}

export default FASTApplicationForm
