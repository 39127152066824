import React, { useState } from "react"
import { Button, Modal, Carousel, Card } from "react-bootstrap"
import { AiFillCloud, AiFillRobot, AiOutlineStock } from "react-icons/ai"
import { BsArrowRight } from "react-icons/bs"
import { FaHandshake } from "react-icons/fa"
import { HiOfficeBuilding } from "react-icons/hi"
import { MdDeveloperMode } from "react-icons/md"
import { RoundButton } from "../../../components/buttons/round"
import FASTApplicationForm from "../../../components/forms/fastapply"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

// Images
const flapbuilding1 = require("../../../images/flapbuilding1.jpg")
const flapbuilding2 = require("../../../images/flapbuilding2.jpg")
const flapbuilding3 = require("../../../images/flapbuilding3.jpg")
const flapbuilding4 = require("../../../images/flapbuilding4.jpg")
const flapbuilding5 = require("../../../images/flapbuilding5.jpg")
const flapbuilding6 = require("../../../images/flapbuilding6.jpg")
const flapbuilding7 = require("../../../images/flapbuilding7.jpg")
const flapbuilding10 = require("../../../images/flapbuilding10.jpg")
const flapbuilding11 = require("../../../images/flapbuilding11.jpg")
const flapbuilding12 = require("../../../images/flapbuilding12.jpg")
const flapbuilding13 = require("../../../images/flapbuilding13.jpg")
const flapbuilding14 = require("../../../images/flapbuilding14.jpg")
const flapbuilding15 = require("../../../images/flapbuilding15.jpg")

const intel = require("../../../images/intel.png")
const florida = require("../../../images/florida.png")
const microsoft = require("../../../images/microsoft.png")
const impactventure = require("../../../images/impactventure.png")
const takedaLogo = require("../../../images/takeda_logo.png")
const africorp = require("../../../images/africorp.png")
const lafricorp = require("../../../images/lafricorp.png")
const aiacademy = require("../../../images/aiacademy.png")
const aisolutions = require("../../../images/aisolutions.png")
const healthtech = require("../../../images/healthcare_reflection.png")
const edutech = require("../../../images/education_items.png")
const agtech = require("../../../images/drone.png")
const floorplan = require("../../../images/floorplan.jpeg")
const exteriorflap = require("../../../images/exteriorflap.jpg")

// graph images
const equityfundingyearly = require("../../../images/equityfundingyearly.png")
const equityfundingindustry = require("../../../images/equityfundingindustry.png")
const equityfunding = require("../../../images/equityfunding.png")

// icons
const ai = require("../../../images/ai.png")
const cloud = require("../../../images/cloud.png")
const iot = require("../../../images/iot.png")
const datascience = require("../../../images/datascience.png")
const fiveG = require("../../../images/5g.png")
const robotics = require("../../../images/robotics.png")
const vr = require("../../../images/vr.png")
const edge = require("../../../images/edge.png")
const architecture = require("../../../images/architecture.png")
const trainee = require("../../../images/trainee.png")
const engineers = require("../../../images/engineers.png")

const AcceleratorPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showGallery, setShowGallery] = useState(false)

  return (
    <Layout>
      {/* Carousel Modal for Popup Images */}
      <Modal show={showGallery} onHide={() => setShowGallery(false)} size="xl">
        <div style={{ maxWidth: "1200px" }}>
          <Carousel controls fade>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding1}
                alt="First slide"
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding2}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding3}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding4}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding5}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding6}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding7}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding10}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding11}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding12}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding13}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding14}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={flapbuilding15}
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </Modal>
      <Modal
        show={showModal}
        onHide={() => setShowConfirmationModal(true)}
        size="lg"
      >
        <FASTApplicationForm onClose={() => setShowConfirmationModal(true)} />
      </Modal>
      <Modal
        centered
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Card>
          <h3 className="my-5 text-center">
            Are you sure you wish to close this form?
          </h3>

          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowModal(false)
                setShowConfirmationModal(false)
              }}
            >
              Close
            </Button>
          </div>
        </Card>
      </Modal>
      <SEO title="Accelerator" description="" lang="en" meta={[]} />

      <section className="hero-wrapper fast-hero text-light justify-content-center align-items-center align-items-sm-start">
        <div className="mt-5 mb-1 d-flex gap-2 align-items-center">
          <h1 className="bold" style={{ letterSpacing: "10px" }}>
            FAST
          </h1>
          <h1> Accelerator</h1>
        </div>
        <h5 className="mb-0 text-muted">Flapmax for African Startups</h5>

        {/* Large View Subheader */}
        <h4 className="no-wrap d-none d-md-block my-5 bold text-purple">
          AI Enablement | Developer Sandbox | Office Space | VC Access
        </h4>

        {/* Mobile Subheader */}
        <ul className="mb-4 d-block d-md-none">
          <li className="text-muted my-2">AI Enablement</li>
          <li className="text-muted my-2">Developer Sandbox</li>
          <li className="text-muted my-2">Office Space</li>
          <li className="text-muted my-2">VC Access</li>
        </ul>

        <div className="d-flex mb-5 mt-3 gap-5 flex-column flex-sm-row align-items-center w-100">
          <Button
            className="btn-purple"
            size="lg"
            onClick={() => setShowModal(true)}
          >
            Apply now
          </Button>
          {/* <p className="mb-0 border border-gold border-2 p-2 text-purple bold">
            Next cohort: <b className="text-light">Summer 2022</b>
          </p> */}
        </div>
      </section>

      {/* Content Block */}
      <section style={{ background: "#f2f1ff" }}>
        <div className="container-fluid">
          <h2>
            Empowering African startups to build sustainable businesses at
            global scale
          </h2>
          <p className="text-muted">
            The Flapmax FAST accelerator program brings advanced technologies,
            business development and international partner network to African
            startups
          </p>

          <div className="row mt-8">
            <div className="my-3 col-lg-3">
              <div className="mb-4 d-flex justify-content-center">
                <AiOutlineStock size={85} />
              </div>
              <h3 className="bold text-center mb-4">AI Enablement</h3>
              <p className="text-muted text-center">
                FAST provides tech startups with a platform to advance AI
                solutions and includes access to Flapmax AI Academy resources
                and tools.
              </p>
            </div>

            <div className="my-3 col-lg-3">
              <div className="mb-4 d-flex justify-content-center">
                <MdDeveloperMode size={75} />
              </div>
              <h3 className="bold text-center mb-4">Developer Sandbox</h3>
              <p className="text-muted text-center">
                The Developer Sandbox is a testing platform designed and
                developed to assist developers with scaling their products and
                services.
              </p>
            </div>

            <div className="my-3 col-lg-3">
              <div className="mb-4 d-flex justify-content-center">
                <HiOfficeBuilding size={75} />
              </div>
              <h3 className="bold text-center mb-4">Office Space</h3>
              <p className="text-muted text-center">
                FAST will provide a shared space for its first cohort with
                access to broadband internet, conducive offices and a conference
                room.
              </p>
            </div>

            <div className="my-3 col-lg-3">
              <div className="mb-4 d-flex justify-content-center">
                <FaHandshake size={75} />
              </div>
              <h3 className="bold text-center mb-4">Venture Capital</h3>
              <p className="text-muted text-center">
                We partner with a network of VCs that share our common vision of
                building African startups into global enterprises.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Focus Areas */}
      <section>
        <div className="container">
          <h2 className="text-md-center">Focus industries</h2>
          <p
            className="text-muted text-md-center mx-md-auto"
            style={{ maxWidth: "1000px" }}
          >
            We support startups innovating in key industries that can greatly
            leverage AI for societal good at large scales
          </p>

          <div className="row mt-8">
            <div className="my-5 col-lg-4">
              <div className="p-2">
                <div className="mb-4 d-flex justify-content-md-center">
                  <img
                    alt=""
                    className="img-fluid shadow-sm"
                    src={healthtech}
                  />
                </div>
                <h3 className="bold mb-4 ps-md-4 ps-lg-0">Healthcare</h3>
                <ul>
                  <li className="text-muted">Telemedicine</li>
                  <li className="text-muted">Robotic Surgery</li>
                  <li className="text-muted">Genome Sequencing</li>
                  <li className="text-muted">
                    Electronic Health Records (EHR)
                  </li>
                </ul>
              </div>
            </div>

            <div className="my-5 col-lg-4">
              <div className="p-2">
                <div className="mb-4 d-flex justify-content-md-center">
                  <img alt="" className="img-fluid shadow-sm" src={edutech} />
                </div>
                <h3 className="bold mb-4 ps-md-4 ps-lg-0">Education</h3>
                <ul>
                  <li className="text-muted">Virtual reality</li>
                  <li className="text-muted">Certification tools</li>
                  <li className="text-muted">Adaptive learning</li>
                  <li className="text-muted">Online assessments</li>
                </ul>
              </div>
            </div>

            <div className="my-5 col-lg-4">
              <div className="p-2">
                <div className="mb-4 d-flex justify-content-md-center">
                  <img alt="" className="img-fluid shadow-sm" src={agtech} />
                </div>
                <h3 className="bold mb-4 ps-md-4 ps-lg-0">Industrial</h3>
                <ul>
                  <li className="text-muted">Security</li>
                  <li className="text-muted">Logistics</li>
                  <li className="text-muted">Agriculture</li>
                  <li className="text-muted">Manufacturing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Africa Section */}
      <section>
        <div className="container-fluid">
          <h2 className="text-center">Why Africa</h2>

          <div className="row mt-8">
            <div className="my-3 col-lg-4">
              <img
                src={equityfundingyearly}
                alt="Startup funding per year."
                className="img-fluid"
              />
            </div>
            <div className="my-3 col-lg-4">
              <img src={equityfunding} alt="" className="img-fluid" />
            </div>
            <div className="my-3 col-lg-4">
              <img src={equityfundingindustry} alt="" className="img-fluid" />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-5">
          <span className="text-muted">
            All data courtesy of Partech ("2020 Africa Tech Venture Capital
            Report")
          </span>
        </div>
      </section>

      <div className="bg-gray">
        {/* Content Block */}
        <section>
          <div className="container">
            <h2 className="text-md-center">
              Ecosystem of builders and enablers​
            </h2>
            <p
              className="text-muted text-md-center mx-md-auto"
              style={{ maxWidth: "1000px" }}
            >
              You’ve built a ground-breaking solution. Let’s help you deploy and
              scale it. We offer a hybrid industry/academic ecosystem comprising
              both Flapmax engineers and our global network of academic &amp;
              industry partners​
            </p>

            <div className="row mt-8">
              <div className="my-3 col-lg-6">
                <h3 className="mb-3 bold">
                  Build AI-enabled solutions more quickly, accelerate
                  time-to-market​
                </h3>
                <p>
                  All teams will get the necessary support to build sustainable
                  solutions and launch FASTER by using our AI expertise,
                  developer tools and other resources.​
                </p>
                <p>
                  Embark on an ecosystem building agenda ensuring our
                  collaboration goes beyond the accelerator program.
                </p>

                <div className="row mt-5 pe-5">
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={ai} alt="" height={75} />
                    <span className="mt-3 text-center">AI</span>
                  </div>
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={cloud} alt="" height={75} />
                    <span className="mt-3 text-center">Cloud</span>
                  </div>
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={iot} alt="" height={75} />
                    <span className="mt-3 text-center">IoT</span>
                  </div>
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={datascience} alt="" height={75} />
                    <span className="mt-3 text-center">Data Science</span>
                  </div>
                </div>
                <div className="row mt-3 pe-5">
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={fiveG} alt="" height={75} />
                    <span className="mt-3 text-center">5G</span>
                  </div>
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={robotics} alt="" height={75} />
                    <span className="mt-3 text-center">Robotics</span>
                  </div>
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={vr} alt="" height={75} />
                    <span className="mt-3 text-center">AR/VR</span>
                  </div>
                  <div className="my-3 col-lg-3 col-md-3 col-6 d-flex flex-column align-items-center">
                    <img src={edge} alt="" height={75} />
                    <span className="mt-3 text-center no-wrap">
                      Edge Computing
                    </span>
                  </div>
                </div>
              </div>
              <div className="my-3 col-lg-6 d-flex justify-content-center">
                <img src={aisolutions} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        {/* Content Block */}
        <section className="my-8">
          <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="my-3 col-lg-6 d-flex justify-content-center">
                <img src={aiacademy} alt="" className="img-fluid" />
              </div>
              <div className="my-3 col-lg-6">
                <h3 className="bold mb-3">
                  Leverage additional resources available through Flapmax AI
                  Academy
                </h3>
                <p>
                  Flapmax AI Academy provides an environment for advancing human
                  potential through educational and training programs designed
                  for the modern workforce.​
                </p>
                <p>
                  Startups selected to participate in the FAST accelerator
                  program will have an opportunity to work with pre-screened
                  interns from foreign universities –in-person or virtually; and
                  will have access to both skilled staff and passionate
                  on-the-job learners.
                </p>

                {/* Icons / Highlights*/}
                <div className="row mt-8">
                  <div className="col-md-4 my-3 d-flex flex-column align-items-center">
                    <img src={trainee} alt="" height={75} />
                    <span className="text-center mt-3">
                      Trainees &amp; Apprentices
                    </span>
                  </div>
                  <div className="col-md-4 my-3 d-flex flex-column align-items-center">
                    <img src={architecture} alt="" height={75} />
                    <span className="text-center mt-3">
                      Solutions Architect
                    </span>
                  </div>
                  <div className="col-md-4 my-3 d-flex flex-column align-items-center">
                    <img src={engineers} alt="" height={75} />
                    <span className="text-center mt-3">Expert Advisors</span>
                  </div>
                </div>

                <div className="mt-8 d-flex justify-content-end">
                  <RoundButton
                    caption="Learn more about Flapmax AI Academy"
                    btnChild={<BsArrowRight size={20} />}
                    btnSize="sm"
                    link="/ai-academy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Content Block */}
      <section>
        <div className="container">
          <div className="d-flex flex-column flex-md-row gap-2">
            <h2 className="bold mb-1" style={{ letterSpacing: "6px" }}>
              FAST
            </h2>
            <h2>3-month Accelerator program</h2>
          </div>
          <h3 className="text-muted mb-5 mt-2">In a nutshell:</h3>
          <div className="row">
            <div className="my-3 col-lg-6">
              <ul>
                <li className="my-3 lg">Developer sandbox</li>
                <li className="my-3 lg">VC access and intros</li>
                <li className="my-3 lg">Matching startups with interns</li>
                <li className="my-3 lg">Training and workshop webinars</li>
                <li className="my-3 lg">Mentorship from AI domain experts</li>
                <li className="my-3 lg">
                  Business development and partner engagements
                </li>
                <li className="my-3 lg">
                  Office space with a variety of floorplan configurations
                </li>
              </ul>

              <button
                className="btn btn-square btn-lg my-5 d-block mx-auto"
                onClick={() => setShowGallery(true)}
              >
                View photo gallery
              </button>
            </div>
            <div className="my-3 col-lg-6 ps-lg-5">
              <img src={floorplan} alt="" className="img-fluid ms-lg-5" />
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gray">
        <div className="container">
          <h2 className="text-md-center">
            Network of VCs, industry and academic partners
          </h2>
          <p className="text-muted text-md-center mx-md-auto mb-8">
            We are proudly working with several organizations to enable your
            business scale more easily, increase the chances for expansion
            beyond regional borders, while creating local job opportunities. Get
            in the door now and apply!​
          </p>

          <div className="row justify-content-end">
            {/* Left-size column */}
            {/* <div className="my-3 col-lg-2 me-lg-5">
              <h3 className="bold mb-3">Industry</h3> */}
            {/* First Row */}
            {/* <div className="row mb-3 flex-column gap-5">
                <div
                  className="col-lg-12 d-flex flex-center p-5"
                  style={{ background: "white" }}
                >
                  <img src={intel} alt="Intel" height={69} />
                </div>

                <div
                  className="col-lg-12 d-flex flex-center p-5"
                  style={{ background: "white" }}
                >
                  <img src={microsoft} alt="Microsoft" />
                </div>
              </div> */}

            {/* Second Row */}
            {/* <div className="row mt-3 flex-column">
                <h3 className="bold mt-5">Academic</h3>
                <div
                  className="my-3 col-lg-12 d-flex flex-center p-5"
                  style={{ background: "white" }}
                >
                  <div>
                    <img src={florida} alt="" />
                  </div>
                </div>
              </div>
            </div> */}
            {/* End left-side column */}

            {/* Right-side Column */}
            {/* <div className="my-3 col-lg-8">
              <h3 className="bold mb-3">VC</h3>
              <div className="row gap-5">
                <div
                  className="col-lg-3 d-flex flex-center p-5"
                  style={{ background: "white" }}
                >
                  <img src={africorp} alt="Africorp Capital" />
                </div>
                <div
                  className="col-lg-3 d-flex flex-center p-5"
                  style={{ background: "white" }}
                >
                  <img
                    src={takedaLogo}
                    alt="Takeda Ventures"
                    className="img-fluid"
                  />
                </div>
                <div
                  className="col-lg-3 d-flex flex-center p-5"
                  style={{ background: "white" }}
                >
                  <img src={impactventure} alt="Impact Venture" />
                </div>
                <div
                  style={{ maxWidth: "740px" }}
                  className="position-relative d-flex flex-center"
                >
                  <Button
                    className="position-absolute"
                    variant="light"
                    size="lg"
                    onClick={() => setShowModal(true)}
                  >
                    Apply now
                  </Button>
                  <img src={exteriorflap} className="img-fluid shadow" />
                </div>
              </div> */}
            {/* End right side column */}
          </div>
          {/* End row */}
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-4">
              <div className="row d-lg-flex flex-lg-column justify-content-around h-100 mt-8 mt-lg-0">
                <div className="col-lg-12 col-3 d-flex justify-content-center align-items-center">
                  <img
                    src={microsoft}
                    alt=""
                    height={56}
                    className="img-md-fluid"
                  />
                </div>
                <div className="col-lg-12 col-3 d-flex justify-content-center align-items-center">
                  <img
                    src={intel}
                    alt=""
                    height={45}
                    className="img-md-fluid"
                  />
                </div>
                <div className="col-lg-12 col-3 d-flex justify-content-center align-items-center">
                  <img
                    src={florida}
                    alt=""
                    height={50}
                    className="img-md-fluid"
                  />
                </div>
                <div className="col-lg-12 col-3 d-flex justify-content-center align-items-center">
                  <img
                    src={lafricorp}
                    alt=""
                    height={85}
                    className="img-md-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <img src={exteriorflap} className="img-fluid shadow" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray d-flex justify-content-center">
        <Button variant="dark" size="lg" onClick={() => setShowModal(true)}>
          Apply now
        </Button>
      </section>
    </Layout>
  )
}

export default AcceleratorPage
